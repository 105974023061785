import { Roles, SIZING_CLASS_TYPE } from 'config';
import { PromiseApi } from 'types/kecho/api-promise';
import { IFilterRequest, IFilterRequestWithOutPaging } from 'types/query/filter';
import axiosAuthClient from 'utils/axios/axiosAuthClient';
import { appendParamsArray, buildUrl } from '../../utils/buildApi';
import { AxiosRequestConfig } from 'axios';

const BaseApi = 'center/';

const centreApi = {
    listCentre(params?: IFilterRequestWithOutPaging): Promise<PromiseApi> {
        const base = `${BaseApi}list-center`;
        const url = buildUrl(base, params);
        return axiosAuthClient.get(url);
    },
    getDetailsById(id: string): Promise<PromiseApi> {
        const base = `${BaseApi}${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },
    listStaffOfCentre(id: string, params: IFilterRequest): Promise<PromiseApi> {
        const base = `${BaseApi}${id}/list-staff`;
        const url = buildUrl(base, params);
        return axiosAuthClient.get(url);
    },

    listSizingOfCenter(
        userRole: string,
        centerId: string,
        centerIds: string[] = [],
        classType: string,
        params: IFilterRequest,
        body: { centerIds?: string[] | null; clusterId?: string | null; brandId?: string | null; isAllCenter?: boolean | null } | null
    ): Promise<PromiseApi> {
        const isOverall = classType === SIZING_CLASS_TYPE.OVERALL;
        const isClassBreakdown = classType === SIZING_CLASS_TYPE.CLASS_BREAKDOWN;
        let base = '';

        if (isOverall) {
            base = `sizing/centers/${SIZING_CLASS_TYPE.OVERALL}`;
            const baseOverallUrl = buildUrl(base, params);
            return axiosAuthClient.post(baseOverallUrl, body);
        }

        if (isClassBreakdown) {
            base = `sizing/${SIZING_CLASS_TYPE.CLASS_BREAKDOWN}`;
            let baseUrl = buildUrl(base, params);
            if (userRole !== Roles.Principals) {
                baseUrl = appendParamsArray(baseUrl, centerIds);
            } else {
                baseUrl = appendParamsArray(baseUrl, [centerId]);
            }
            return axiosAuthClient.get(baseUrl);
        }
        // Thêm return mặc định hoặc throw error
        throw new Error(`Invalid class type: ${classType}`);
    },

    exportSizing(
        userRole: string,
        centerId: string,
        centerIds: string[] = [],
        classType: string,
        params: { fromDate: string; toDate: string },
        body: { centerIds?: string[]; clusterId?: string | null; brandId?: string | null; isAllCenter?: boolean | null } | null,
        config?: AxiosRequestConfig
    ): Promise<PromiseApi> {
        const isOverall = classType === SIZING_CLASS_TYPE.OVERALL;
        const isClassBreakdown = classType === SIZING_CLASS_TYPE.CLASS_BREAKDOWN;
        let base = '';

        const exportConfig: AxiosRequestConfig = {
            ...config,
            responseType: 'blob'
        };

        if (isOverall) {
            base = `sizing/${BaseApi}${SIZING_CLASS_TYPE.OVERALL}/export`;
            const baseOverallUrl = buildUrl(base, params);
            return axiosAuthClient.post(baseOverallUrl, body, exportConfig);
        }

        if (isClassBreakdown) {
            base = `sizing/${SIZING_CLASS_TYPE.CLASS_BREAKDOWN}/export`;
            let baseUrl = buildUrl(base, params);
            if (userRole !== Roles.Principals) {
                baseUrl = appendParamsArray(baseUrl, centerIds);
            } else {
                baseUrl = appendParamsArray(baseUrl, [centerId]);
            }
            return axiosAuthClient.get(baseUrl, exportConfig);
        }

        // Thêm return mặc định hoặc throw error
        throw new Error(`Invalid class type: ${classType}`);
    }
};
export default centreApi;
