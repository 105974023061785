import { CardContent, FormControl, Grid, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Tooltip } from '@mui/material';
import { IconDownload } from '@tabler/icons';
import ArrowDownIcon from 'assets/images/pages/arrow-down.svg';
import CustomSelectCenterClassBreakdown from 'components/CustomSelectCenter';
import LocalDesktopDatePicker from 'components/LocalDesktopDatePicker';
import DateRangeLocalDesktopDatePicker from 'components/LocalDesktopDateRangePicker';
import ModalCustom from 'components/Modal/ModalCustom';
import { AUTH_LOCAL_STORAGE_KEY, GET_ALL_REQUEST, Roles, SIZING_CLASS_TYPE, formatDate, useStyles } from 'config';
import UIManager from 'core/UIManager';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { CentreApi, clusterApi } from 'services/api';
import CustomSelectCenter from './CustomSelectCenter';
import CustomSelectCluster from './CustomSelectCluster';
import CustomSelectBrand from './CustomSelectBrand';
import brandApi from 'services/api/brandApi';

interface IEditViewModal {
    onClose: any;
}
const ExportSizingModal = ({ onClose }: IEditViewModal) => {
    const classes = useStyles();
    const [centerData, setCenterData] = useState<any[]>([]);
    const [centerId, setCenterId] = useState<string>('');
    const [centerIds, setCenterIds] = useState<string[]>([]);
    const [clusterData, setClusterList] = useState<any>([]);
    const [brandData, setBrandList] = useState<any>([]);
    const [selectedClusterId, setSelectedClusterId] = useState<string>('');
    const [isOpenModalCenterSelect, setIsOpenModalCenterSelect] = useState<boolean>(false);
    const [selectOption, setSelectOption] = useState<string>('All centers');
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const userCenterID = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.SELECT_CENTER_ID) || '';
    const userCenterName = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center[0]?.nameCenter;
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [filterFirst, setFilterFirst] = useState<string>('this');
    const [filterLast, setFilterLast] = useState<string>('day');
    const [filterClass, setFilterClass] = useState<string>(SIZING_CLASS_TYPE.OVERALL);
    const [filterNumber, setFilterNumber] = useState(1);
    const [filterRanger, setFilterRanger] = useState(() => {
        const defaultDate = moment().format(formatDate.DATE_DEFAULT);
        return {
            date: defaultDate,
            fromDate: defaultDate,
            toDate: defaultDate
        };
    });
    const [isFilterError, setIsFilterError] = useState<boolean>(false);
    const [messageError, setMessageError] = useState<string>('');
    const [disabledBtnExport, setDisabledBtnExport] = useState<boolean>(false);
    const [selectedBrandId, setSelectedBrandId] = useState<string>('');

    // const csvLink = useRef<any>(null);

    useEffect(() => {
        handleGetCentreList();
    }, []);

    const handleGetCentreList = async () => {
        if (isShowFilter()) {
            const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);
            if (dataList) {
                setCenterData(dataList);
                setCenterIds([dataList[0]?.id]);
                setCenterId(dataList[0]?.id);
            }
        } else {
            setCenterId(userCenterID);
        }
    };

    const handleGetClusterList = async () => {
        const { dataList } = await clusterApi.listCluster();
        if (dataList) {
            setClusterList(dataList);
            setSelectedClusterId(dataList[0].id);
        }
    };

    const handleGetBrandList = async () => {
        const { dataList } = await brandApi.listBrand();
        if (dataList) {
            setBrandList(dataList);
            setSelectedBrandId(dataList[0].id);
        }
    };

    const handleExport = async () => {
        setIsExporting(true);
        let newCenterId = centerId;
        const param = {
            fromDate: filterRanger.fromDate,
            toDate: filterRanger.toDate
        };
        // Build payload based on selected category
        let payload = null;
        if (filterClass === SIZING_CLASS_TYPE.OVERALL) {
            switch (selectOption) {
                case 'All centers':
                    payload = {
                        isAllCenter: true
                    };
                    break;
                case 'Cluster':
                    if (selectedClusterId) {
                        payload = {
                            clusterId: selectedClusterId
                        };
                    } else {
                        return;
                    }
                    break;
                case 'Brand':
                    payload = {
                        brandId: selectedBrandId
                    };
                    break;
                case 'Select centers':
                    payload = {
                        centerIds
                    };
                    break;
                default:
                    break;
            }
        }

        if (!isShowFilter()) {
            newCenterId = userCenterID;
        }

        try {
            const res: any = await CentreApi.exportSizing(
                userRole,
                newCenterId,
                centerIds,
                filterClass,
                param,
                filterClass === SIZING_CLASS_TYPE.OVERALL ? payload : null,
                { responseType: 'blob' }
            );
            setIsExporting(false);
            if (!res?.data) {
                UIManager.showAlert({
                    message: 'No data to export. Please choose a different time period',
                    alert: {
                        color: 'error'
                    }
                });
                return;
            }
            // Create blob from response and download
            const blob = new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            // const filename = res.headers['content-disposition']?.split('filename=')[1]?.replace(/"/g, '') || 'download.xlsx';
            const filename = `${nameExport()}_${convertDateSlashToHyphen(filterRanger.fromDate)}_${convertDateSlashToHyphen(
                filterRanger.toDate
            )}.xlsx`;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            UIManager.showAlert({
                message: 'Failed to export file',
                alert: {
                    color: 'error'
                }
            });
        }
    };

    const setDefaultDate = () => {
        setFilterRanger((prev) => ({
            ...prev,
            date: moment().format(formatDate.DATE_DEFAULT),
            fromDate: moment().format(formatDate.DATE_DEFAULT),
            toDate: moment().format(formatDate.DATE_DEFAULT)
        }));
    };

    const setDefaultDateRange = () => {
        setFilterRanger((prev) => ({
            ...prev,
            fromDate: moment().format(formatDate.DATE_DEFAULT),
            toDate: moment().add(1, 'days').format(formatDate.DATE_DEFAULT)
        }));
    };

    // date filter current day
    const updateFilterWithDate = (date: string) => {
        setFilterRanger({ ...filterRanger, fromDate: date, toDate: date });
    };

    // date filter next/past {number} day
    const isSoFilterDayDate = (type: string, number?: number) => {
        if (type === 'next') {
            setFilterRanger({
                ...filterRanger,
                fromDate: moment().add(1, 'days').format(formatDate.DATE_DEFAULT),
                toDate: moment().add(number, 'days').format(formatDate.DATE_DEFAULT)
            });
        } else if (type === 'past') {
            setFilterRanger({
                ...filterRanger,
                fromDate: moment().subtract(number, 'days').format(formatDate.DATE_DEFAULT),
                toDate: moment().subtract(1, 'days').format(formatDate.DATE_DEFAULT)
            });
        } else if (type === 'this') {
            setFilterRanger((prev) => ({
                ...prev,
                fromDate: moment().format(formatDate.DATE_DEFAULT),
                toDate: moment().format(formatDate.DATE_DEFAULT),
                page: 1
            }));
        }
    };

    // date filter next/past {number} week
    const isSoFilterWeekDate = (type: string, number?: number) => {
        if (type === 'next') {
            setFilterRanger({
                ...filterRanger,
                fromDate: moment().isoWeekday(1).add(1, 'weeks').format(formatDate.DATE_DEFAULT),
                toDate: moment().isoWeekday(5).add(number, 'weeks').format(formatDate.DATE_DEFAULT)
            });
        } else if (type === 'past') {
            setFilterRanger({
                ...filterRanger,
                fromDate: moment().isoWeekday(1).subtract(number, 'weeks').format(formatDate.DATE_DEFAULT),
                toDate: moment().isoWeekday(5).subtract(1, 'weeks').format(formatDate.DATE_DEFAULT)
            });
        } else if (type === 'this') {
            setFilterRanger((prev) => ({
                ...prev,
                fromDate: moment().isoWeekday(1).format(formatDate.DATE_DEFAULT),
                toDate: moment().isoWeekday(5).format(formatDate.DATE_DEFAULT),
                page: 1
            }));
        }
    };

    // date filter next/past {number} month
    const isSoFilterMonthDate = (type: string, number?: number) => {
        if (type === 'next') {
            setFilterRanger({
                ...filterRanger,
                fromDate: moment().add(1, 'months').startOf('month').format(formatDate.DATE_DEFAULT),
                toDate: moment().add(number, 'months').endOf('month').format(formatDate.DATE_DEFAULT)
            });
        } else if (type === 'past') {
            setFilterRanger({
                ...filterRanger,
                fromDate: moment().subtract(number, 'months').startOf('month').format(formatDate.DATE_DEFAULT),
                toDate: moment().subtract(1, 'months').endOf('month').format(formatDate.DATE_DEFAULT)
            });
        } else if (type === 'this') {
            setFilterRanger((prev) => ({
                ...prev,
                fromDate: moment().startOf('month').format(formatDate.DATE_DEFAULT),
                toDate: moment().endOf('month').format(formatDate.DATE_DEFAULT),
                page: 1
            }));
        }
    };

    const validateFilter = (first: string, number: number, last: string) => {
        if (!number) {
            setMessageError('Please enter a number');
            return false;
        }
        if (number <= 0) {
            setMessageError('Please enter a number greater than 0');
            return false;
        }
        if (number > 90) {
            setMessageError('Please enter a number less than 90');
            return false;
        }

        if (first === 'select-date' || first === 'range-date') {
            setMessageError('');
            return true;
        }
        if (first === 'this' || first === 'next' || first === 'past') {
            if (last === 'day') {
                if (number > 0 && number <= 90) {
                    setMessageError('');
                    return true;
                }
                setMessageError('Please enter a number between 1 and 90 of day');
            } else if (last === 'week') {
                if (number <= 12) {
                    setMessageError('');
                    return true;
                }
                setMessageError('Please enter a number between 1 and 12 of week');
            } else if (last === 'month') {
                if (number <= 3) {
                    setMessageError('');
                    return true;
                }
                setMessageError('Please enter a number between 1 and 3 of month');
            }
        }
        return false;
    };

    const handleChangeFirst = (e: any) => {
        const first = e.target.value;
        if (!validateFilter(first, filterNumber, filterLast)) {
            setDisabledBtnExport(true);
        } else {
            setDisabledBtnExport(false);
        }

        const rangerNumber = 1;
        setMessageError('');
        setFilterNumber(rangerNumber);

        if (first === 'this') {
            setFilterLast('day');
            updateFilterWithDate(moment().format(formatDate.DATE_DEFAULT));
        } else if (first === 'next') {
            if (filterLast === 'day') {
                isSoFilterDayDate('next', rangerNumber);
            } else if (filterLast === 'week') {
                isSoFilterWeekDate('next', rangerNumber);
            } else if (filterLast === 'month') {
                isSoFilterMonthDate('next', rangerNumber);
            }
        } else if (first === 'past') {
            if (filterLast === 'day') {
                isSoFilterDayDate('past', rangerNumber);
            } else if (filterLast === 'week') {
                isSoFilterWeekDate('past', rangerNumber);
            } else if (filterLast === 'month') {
                isSoFilterMonthDate('past', rangerNumber);
            }
        } else if (first === 'select-date') {
            setDefaultDate();
        } else if (first === 'range-date') {
            setDefaultDateRange();
        }
    };

    const handleChangeInputNumber = (e: any) => {
        let number = e.target.value;
        // Loại bỏ các ký tự không phải số
        number = number.replace(/\D/g, '');
        if (number === '') {
            number = 0;
            return;
        }
        // Nếu giá trị bắt đầu bằng "0" và có thêm số khác, loại bỏ "0"
        if (number.length > 1 && number.startsWith('0')) {
            number = number.slice(1);
        }
        setFilterNumber(number);

        if (!validateFilter(filterFirst, number, filterLast)) {
            setDisabledBtnExport(true);
        } else {
            setDisabledBtnExport(false);
        }
        if (filterFirst === 'next') {
            if (filterLast === 'day') {
                isSoFilterDayDate('next', number);
            } else if (filterLast === 'week') {
                isSoFilterWeekDate('next', number);
            } else if (filterLast === 'month') {
                isSoFilterMonthDate('next', number);
            }
        } else if (filterFirst === 'past') {
            if (filterLast === 'day') {
                isSoFilterDayDate('past', number);
            } else if (filterLast === 'week') {
                isSoFilterWeekDate('past', number);
            } else if (filterLast === 'month') {
                isSoFilterMonthDate('past', number);
            }
        }
    };

    const handleChangeLast = (e: any) => {
        const last = e.target.value;
        if (!validateFilter(filterFirst, filterNumber, last)) {
            setDisabledBtnExport(true);
        } else {
            setDisabledBtnExport(false);
        }
        if (last === 'day') {
            if (filterFirst === 'next') {
                isSoFilterDayDate('next', filterNumber);
            } else if (filterFirst === 'past') {
                isSoFilterDayDate('past', filterNumber);
            } else if (filterFirst === 'this') {
                isSoFilterDayDate('this');
            }
        } else if (last === 'week') {
            if (filterFirst === 'next') {
                isSoFilterWeekDate('next', filterNumber);
            } else if (filterFirst === 'past') {
                isSoFilterWeekDate('past', filterNumber);
            } else if (filterFirst === 'this') {
                isSoFilterWeekDate('this');
            }
        } else if (last === 'month') {
            if (filterFirst === 'next') {
                isSoFilterMonthDate('next', filterNumber);
            } else if (filterFirst === 'past') {
                isSoFilterMonthDate('past', filterNumber);
            } else if (filterFirst === 'this') {
                isSoFilterMonthDate('this');
            }
        }
    };

    const isShowFilter = () => {
        return userRole === Roles.Cluster || userRole === Roles.Admin || userRole === Roles.HrAdmin || userRole === Roles.HrIc;
    };

    const isShowClusterCategory = () => {
        return userRole === Roles.Admin || userRole === Roles.HrAdmin;
    };

    const nameExport = () => {
        if (filterClass === SIZING_CLASS_TYPE.OVERALL) {
            if (isShowFilter()) {
                return centerData?.filter((item) => item.id === centerId)[0]?.nameCenter;
            }
            return userCenterName;
        }

        return 'Class Breakdown Sizing Page';
    };

    const convertDateSlashToHyphen = (date: string) => {
        return date.replace(/\//g, '-');
    };

    const handleOnChangeCenterSelect = (e: any) => {
        setCenterIds(e.target.value);
    };

    const handleOnchangeBrand = (e: any) => {
        const value = e.target.value;
        setFilterRanger((prev) => ({
            ...prev,
            brandId: value
        }));
        setSelectedBrandId(value);
    };

    const handleOnchangeCluster = (e: any) => {
        const value = e.target.value;
        setFilterRanger((prev) => ({
            ...prev,
            clusterId: value
        }));
        setSelectedClusterId(value);
    };

    const handleChangeCategory = (event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value;
        setSelectOption(selectedValue);

        switch (selectedValue) {
            case 'All centers':
                setFilterRanger((prev) => ({
                    ...prev,
                    isAllCenter: true
                }));
                break;
            case 'Cluster':
                handleGetClusterList();
                break;
            case 'Brand':
                handleGetBrandList();
                break;
        }
    };

    const handleDatePickerChange = (e: any) => {
        const currentDate = moment().format(formatDate.DATE_DEFAULT);
        const date = e ? moment(e).format(formatDate.DATE_DEFAULT) : '';
        const fromDate = new Date(currentDate);
        const toDate = new Date(date);
        const diffTime = Math.abs(toDate.getTime() - fromDate.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const diffMonths = Math.ceil(diffDays / 30);
        if (diffMonths > 3) {
            setMessageError('Please select a range within 90 days before or after today');
            return;
        }
        setMessageError('');
        if (e) {
            setIsFilterError(false);
        } else {
            setIsFilterError(true);
        }
        setFilterRanger((prev) => {
            return { ...prev, date, fromDate: date, toDate: date };
        });
    };

    const handleOnChangeDateRange = (value: any) => {
        const fromDate = new Date(value.fromDate);
        const toDate = new Date(value.toDate);
        const diffTime = Math.abs(toDate.getTime() - fromDate.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const diffMonths = Math.ceil(diffDays / 30);

        if (diffMonths > 3) {
            setMessageError('Please select a range within 90 days before or after today');
            return;
        }
        setFilterRanger((prev) => ({ ...prev, ...value }));
    };

    const filteredCenterNames = useMemo(() => {
        const lessCenterIds = [...centerIds].splice(1);
        const fullCenterIds = [...centerIds];
        if (centerIds.length === centerData.length) {
            return centerData
                ?.filter((item) => fullCenterIds.includes(item.id as string))
                .map((item) => item.nameCenter)
                .join(', ');
        }
        return centerData
            ?.filter((item) => lessCenterIds.includes(item.id as string))
            .map((item) => item.nameCenter)
            .join(', ');
    }, [centerData, centerIds]);
    const CustomIconComponent = () => {
        const isSumCenter = centerIds.length === centerData.length;
        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
            <div
                style={{
                    right: '10px',
                    position: 'relative',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transform: 'none',
                    cursor: 'pointer'
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenModalCenterSelect(true);
                }}
            >
                {centerIds.length > 1 && (
                    <Tooltip title={filteredCenterNames}>
                        <div style={{ minWidth: '65px', display: 'flex', marginRight: '6px', fontSize: '12px', fontWeight: 600 }}>
                            {isSumCenter ? `+ ${centerIds?.length} more` : `+ ${centerIds?.length - 1} more`}
                        </div>
                    </Tooltip>
                )}
                <img src={ArrowDownIcon} alt="&darr;" />
            </div>
        );
    };

    const renderExportSizing = () => {
        return (
            <Paper className="export-sizing">
                <CardContent style={{ padding: 0 }}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <div className="group-filter" style={{ marginBottom: 0 }}>
                                <FormControl variant="outlined">
                                    <InputLabel size="small" id="sizing-page__select-class__label">
                                        Class
                                    </InputLabel>
                                    <Select
                                        labelId="sizing-page__select-class"
                                        id="sizing-page__select-class_select"
                                        name="isClass"
                                        label="Class"
                                        sx={{ minWidth: 100 }}
                                        value={filterClass}
                                        size="small"
                                        onChange={(e) => {
                                            setFilterClass(e.target.value);
                                        }}
                                    >
                                        <MenuItem value={SIZING_CLASS_TYPE.OVERALL}>Overall</MenuItem>
                                        <MenuItem value={SIZING_CLASS_TYPE.CLASS_BREAKDOWN}>Class Breakdown</MenuItem>
                                    </Select>
                                </FormControl>
                                {filterClass === SIZING_CLASS_TYPE.OVERALL && (
                                    <>
                                        <FormControl variant="outlined" sx={{ marginLeft: '10px' }}>
                                            <InputLabel size="small" id="sizing-page__select-class__label">
                                                Category
                                            </InputLabel>
                                            <Select
                                                labelId="sizing-page__select-class"
                                                id="sizing-page__select-class_select"
                                                name="class"
                                                label="Category"
                                                value={selectOption}
                                                size="small"
                                                sx={{ minWidth: 100 }}
                                                onChange={handleChangeCategory}
                                            >
                                                <MenuItem value="All centers">All centers</MenuItem>
                                                {isShowClusterCategory() && <MenuItem value="Cluster">Cluster</MenuItem>}
                                                <MenuItem value="Brand">Brand</MenuItem>
                                                <MenuItem value="Select centers">Select centers</MenuItem>
                                            </Select>
                                        </FormControl>

                                        {selectOption === 'Brand' && (
                                            <CustomSelectBrand
                                                data={brandData}
                                                componentSx={{
                                                    marginLeft: '10px'
                                                }}
                                                name="brandId"
                                                label="Brand"
                                                onChange={handleOnchangeBrand}
                                                defaultValue={selectedBrandId}
                                                value={selectedBrandId}
                                                selectProps={{
                                                    MenuProps: {
                                                        classes: {
                                                            paper: classes.selectMenu
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                        {selectOption === 'Cluster' && (
                                            <CustomSelectCluster
                                                data={clusterData}
                                                componentSx={{
                                                    marginLeft: '10px'
                                                }}
                                                name="clusterIds"
                                                label="Cluster"
                                                onChange={handleOnchangeCluster}
                                                defaultValue={selectedClusterId}
                                                value={selectedClusterId}
                                                selectProps={{
                                                    MenuProps: {
                                                        classes: {
                                                            paper: classes.selectMenu
                                                        }
                                                    }
                                                }}
                                            />
                                        )}

                                        {isShowFilter() && selectOption === 'Select centers' && (
                                            <CustomSelectCenter
                                                data={centerData}
                                                componentSx={{
                                                    marginLeft: '10px',
                                                    '& .MuiSelect-select': {
                                                        ...(centerIds.length > 1 && {
                                                            paddingRight: '20px !important'
                                                        })
                                                    }
                                                }}
                                                name="centerIds"
                                                label="Center"
                                                onChange={(e: any) => {
                                                    handleOnChangeCenterSelect(e);
                                                }}
                                                value={centerIds}
                                                multiple
                                                selectProps={{
                                                    IconComponent: CustomIconComponent,
                                                    open: isOpenModalCenterSelect,
                                                    onClick: (e) => {
                                                        const element = e.target as HTMLElement;
                                                        if (
                                                            element.childNodes.length <= 0 ||
                                                            (element.childNodes.length === 4 && element.nodeName === 'DIV')
                                                        ) {
                                                            setIsOpenModalCenterSelect(false);
                                                            return;
                                                        }
                                                        setIsOpenModalCenterSelect(true);
                                                    },
                                                    MenuProps: {
                                                        classes: {
                                                            paper: classes.selectMenu
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                    </>
                                )}
                                {filterClass === SIZING_CLASS_TYPE.CLASS_BREAKDOWN && isShowFilter() && (
                                    <CustomSelectCenterClassBreakdown
                                        data={centerData}
                                        componentSx={{
                                            marginLeft: '10px',
                                            '& .MuiSelect-select': {
                                                ...(centerIds.length > 1 &&
                                                    filterClass === SIZING_CLASS_TYPE.CLASS_BREAKDOWN && {
                                                        paddingRight: '20px !important'
                                                    })
                                            }
                                        }}
                                        name="isCenterIds"
                                        label="Center"
                                        // defaultValue={centerData.length > 0 ? [centerData[0]?.id] : []}
                                        value={filterClass === SIZING_CLASS_TYPE.CLASS_BREAKDOWN ? centerIds : centerId}
                                        multiple={filterClass === SIZING_CLASS_TYPE.CLASS_BREAKDOWN}
                                        onChange={(e: any) => {
                                            handleOnChangeCenterSelect(e);
                                        }}
                                        size="small"
                                        selectProps={{
                                            IconComponent:
                                                filterClass === SIZING_CLASS_TYPE.CLASS_BREAKDOWN ? CustomIconComponent : undefined,
                                            open: isOpenModalCenterSelect,
                                            onClick: (e) => {
                                                const element = e.target as HTMLElement;
                                                // Check when clicking outside the select box

                                                if (
                                                    element.childNodes.length <= 0 ||
                                                    (element.childNodes.length === 4 && element.nodeName === 'DIV')
                                                ) {
                                                    setIsOpenModalCenterSelect(false);
                                                    return;
                                                }

                                                if (isOpenModalCenterSelect && filterClass === SIZING_CLASS_TYPE.OVERALL) {
                                                    return;
                                                }

                                                setIsOpenModalCenterSelect(true);
                                            },
                                            MenuProps: {
                                                classes: {
                                                    paper: classes.selectMenu
                                                }
                                            }
                                        }}
                                    />
                                )}

                                <FormControl style={{ width: 130, marginLeft: 10 }}>
                                    <InputLabel size="small" id="demo-simple-select-label">
                                        First
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="isFirst"
                                        value={filterFirst}
                                        label="Filter"
                                        size="small"
                                        onChange={(e) => {
                                            handleChangeFirst(e);
                                            setMessageError('');
                                            setIsFilterError(false);
                                            setFilterFirst(e.target.value);
                                        }}
                                    >
                                        <MenuItem value="this">This</MenuItem>
                                        <MenuItem value="next">Next</MenuItem>
                                        <MenuItem value="past">Past</MenuItem>
                                        <MenuItem value="select-date">Select date</MenuItem>
                                        <MenuItem value="range-date">Date range</MenuItem>
                                    </Select>
                                </FormControl>
                                <div className="date-filter" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                                    {(filterFirst === 'next' || filterFirst === 'past') && (
                                        <FormControl style={{ width: 70, marginLeft: 10 }}>
                                            <TextField
                                                type="number"
                                                className="input-number"
                                                label="Number"
                                                value={filterNumber}
                                                size="small"
                                                onChange={(e) => {
                                                    handleChangeInputNumber(e);
                                                }}
                                                InputProps={{ inputProps: { min: 0 } }}
                                                autoComplete="off"
                                            />
                                        </FormControl>
                                    )}

                                    {(filterFirst === 'this' || filterFirst === 'next' || filterFirst === 'past') && (
                                        <FormControl style={{ width: 100, marginLeft: 10 }}>
                                            <InputLabel size="small" id="demo-simple-select-label">
                                                Last
                                            </InputLabel>
                                            <Select
                                                disabled={!(centerData?.length > 0 || userCenterID)}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="isLast"
                                                value={filterLast}
                                                label="Filter"
                                                size="small"
                                                onChange={(e) => {
                                                    handleChangeLast(e);
                                                    setFilterLast(e.target.value);
                                                }}
                                            >
                                                <MenuItem value="day">Day</MenuItem>
                                                <MenuItem value="week">Week</MenuItem>
                                                <MenuItem value="month">Month</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}

                                    {filterFirst === 'select-date' && (
                                        <LocalDesktopDatePicker
                                            value={filterRanger.date}
                                            sx={{ marginLeft: '10px', width: '100%' }}
                                            isRequired
                                            onChange={handleDatePickerChange}
                                            onError={(error) => {
                                                setIsFilterError(!!error);
                                                if (error) {
                                                    setMessageError('');
                                                }
                                            }}
                                        />
                                    )}
                                    {filterFirst === 'range-date' && (
                                        <DateRangeLocalDesktopDatePicker
                                            isRequired
                                            views={['year', 'month', 'day']}
                                            sx={{ marginLeft: '10px' }}
                                            fromDateValue={filterRanger.fromDate}
                                            toDateValue={filterRanger.toDate}
                                            onChange={handleOnChangeDateRange}
                                            dateRangeErrorText="To Date must be greater than From Date"
                                            onError={() => {
                                                setMessageError('');
                                            }}
                                        />
                                    )}
                                    {/* {messageError && (
                                        <div className="error-message" style={{ position: 'absolute', top: 40, right: 0 }}>
                                            <Typography variant="body2" color="error" style={{ fontSize: 12, whiteSpace: 'nowrap' }}>
                                                {messageError}
                                            </Typography>
                                        </div>
                                    )} */}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Paper>
        );
    };
    return (
        <ModalCustom
            onSave={handleExport}
            onClose={onClose}
            onCancel={onClose}
            disabled={!!(isFilterError || messageError || !(filterRanger.fromDate && filterRanger.toDate))}
            isLoading={isExporting}
            title="Export sizing data"
            contentCancel="Cancel"
            contentSave="Export"
            // sx={{ width: 600, maxHeight: '300px', overflowY: step === 1 ? 'scroll' : 'hidden' }}
            iconSave={IconDownload}
            typeHelper={messageError.length > 0 && messageError ? 'error' : 'warning'}
            msgHelper={messageError}
        >
            <>
                {renderExportSizing()}
                {/* <CSVLink
                    data={sizingData}
                    filename={`${nameExport()}_${convertDateSlashToHyphen(filterRanger.fromDate)}_${convertDateSlashToHyphen(
                        filterRanger.toDate
                    )}.csv`}
                    ref={csvLink}
                    target="_blank"
                /> */}
            </>
        </ModalCustom>
    );
};

export default ExportSizingModal;
